/** @jsx jsx */
import { Component, Fragment } from "react"
import { graphql, Link } from "gatsby"
import { jsx } from "theme-ui"
import { Box, Grid, Heading, Text } from "@theme-ui/components"
import { FindSpace, TheSeo } from "x"

export default class Articles extends Component {
	render() {
		const {
			data: {
				craft: { entry },
			},
			pageContext: { ...pageContext },
		} = this.props

		return (
			<Fragment>
				<TheSeo title={entry.title} />

				<Grid
					gap={4}
					sx={{
						gridTemplateColumns: ["auto", null, "1fr 256px"],
					}}
				>
					<Box>
						<Link
							to={`/articles/`}
							sx={{
								variant: "links.breadcrumb",
							}}
						>
							/ Articles
						</Link>
						<Heading variant="display">{entry.title}</Heading>
						<Text
							variant="bodytext"
							className="reading"
							dangerouslySetInnerHTML={{ __html: entry.body.content }}
						/>
					</Box>
					<Box>
						<FindSpace />
					</Box>
				</Grid>
			</Fragment>
		)
	}
}

export const pageQuery = graphql`
	query QueryArticles($id: [Int]!) {
		craft {
			entry(id: $id) {
				... on Craft_Articles {
					title
					slug
					uri
					id
					postDate
					body {
						content
					}
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
				}
			}
		}
	}
`
